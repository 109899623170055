import "./Reward.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../hooks/useAuthentication";
import Confetti from "react-confetti";
import gif from "../../assets/images/congrats.gif";


export const Reward = () => {
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cashbackAmount, setCashbackAmount] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [confettiStartY, setConfettiStartY] = useState(650); 

  useEffect(() => {
    // Call the API to get the cashback reward
    API.getReferral()
      .then((response) => {
        if (response && response.amount) {
          setCashbackAmount(response.amount);
        }
      })
      .catch((error) => {
        console.error("Error fetching reward:", error);
      });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = () => {
    navigate(ROUTES.PAYMENT_PAGE);
  };

  return (
    <MainLayout className="register-page">
      
      <Confetti
        width={windowWidth}
        height={windowHeight}
        colors={["#C0C0C0", "#D3D3D3", "#A9A9A9"]} 
        numberOfPieces={200}
        recycle={false} 
        initialVelocityX={5}
        initialVelocityY={20}
        confettiSource={{ x: 0, y: confettiStartY, w: windowWidth, h: 10 }} 
      />
      <div className="congrats-form">
      <img
          src={gif}
          alt="Congratulation"
          className="congratulation-image"
        />
        <div className="congratulation-container">
          <p className="congratulation-title">Congratulations!</p>
          <p className="congratulation-text">
            You have won Cashback of
          </p>
          <p className="gradient-text">₹ {cashbackAmount}</p>
        </div>
      </div>
        <button className="btn btn-primary" onClick={handleSubmit}>
          REDEEM NOW
        </button>
    </MainLayout>
  );
};
