import React, { useState, useRef, useEffect } from "react";
import "./WatchVideo.scss";
import MainLayout from "../../layouts/MainLayout";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import API from "../../api";
import video from "../../assets/videos/sterling.mp4";
// import video from "../../assets/videos/sterling.mov";
import img from "../../assets/images/bottom.png";
import vidtitle from "../../assets/images/vidtitle.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import thumbnail from "../../assets/images/video-thumbnail.png";
import playbtn from "../../assets/images/Play.png";

export const WatchVideo = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasSeenVideo, setHasSeenVideo] = useState<boolean>(false);
  const [err, setErr] = useState("");
  console.log("video play");

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnded = () => {
    setHasSeenVideo(true);
    setIsPlaying(false);
  };

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const handleNextClick = () => {
    setErr("");
    if (!hasSeenVideo) {
      setErr("Please watch the video before proceeding.");
      return;
    }

    API.watchVideo()
      .then(() => {
        gtagTrackEvent(GA_EVENTS.Watch_video);
        navigate(ROUTES.PAYMENT_PAGE);
      })
      .catch((err) => {
        const { message } = err;
        setErr(message);
      });
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
      videoElement.addEventListener("ended", handleEnded);

      return () => {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
        videoElement.removeEventListener("ended", handleEnded);
      };
    }
  }, []);

  return (
    <MainLayout className="watch-video-page">
      <img className="title-img" src={vidtitle} alt="title" />

      <div className="video-container">
        <video
          ref={videoRef}
          width="100%"
          autoPlay
          playsInline
          onClick={() => {
            if (videoRef.current) {
              if (videoRef.current.paused) {
                videoRef.current.play();
              }
            }
          }}
          poster={thumbnail}
          preload="auto"
        >
          <source
            src="https://promo-campaign.blr1.cdn.digitaloceanspaces.com/sterling/sterling.mp4"
            type="video/mp4"
          />{" "}
          {/* Use the imported video */}
          Your browser does not support the video tag.
        </video>
        {/* Play Button */}
        {!isPlaying && (
          <img
            src={playbtn}
            alt="Play Button"
            className="play-button"
            onClick={handlePlayClick}
          />
        )}
        <p className="gradient-text">
          Watch the Video to Avail Cashback Up to Rs.100
        </p>
      </div>
      <p className="error">{err}</p>
      <button
        style={{
          position: "relative",
          bottom: "auto",
          // backgroundColor: !isPlaying ? "#F68B1F" : "#cccccc",
        }}
        className="btn btn-primary"
        onClick={handleNextClick}
        // disabled={!isPlaying}
      >
        Next
      </button>
    </MainLayout>
  );
};

export default WatchVideo;
