import MainLayout from "../../layouts/MainLayout";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import footer from "../../assets/images/bottom.png";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import store from "../../store/store";
import { canClaim, setAccessToken, limit, amt } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import PaymentPage from "../PaymentPage/PaymentPage";
// import CashbackPayout from "../CashbackPayout/CashbackPayout";
import { useAuthentication } from "../../hooks/useAuthentication";
import { hideCaptchaBadge, showCaptchaBadge, renderCaptcha } from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { log } from "console";
import arrow from "../../assets/images/arrow.png";
import register from "../../assets/images/Register.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import { useSearchParams } from "react-router-dom";
import regtitle from "../../assets/images/regtitle.png";
import otptitle from "../../assets/images/otptitle.png";


// eslint-disable-next-line @typescript-eslint/no-explicit-any
// declare const turnstile: any;

type StateResponseProps = {
  state: string;
};

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [searchParams] = useSearchParams();
  const referralCodeFromUrl = searchParams.get("referral");
  const referralSet = useRef(false);
  const [tokenValue, setTokenValue] = useState("");

  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
    []
  );
  const [filteredNewStates, setFilteredNewStates] = useState<
    StateResponseProps[]
  >([]);
  const [citiesFound, setCitiesFound] = useState(true);
  const [states, setStates] = useState("");
  const [selectedState, setSelectedState] = useState("");
  // const [cloudFareToken, setCloudFareToken] = useState("");
  // const widgetId = useRef<any>();
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleLocationChange = () => {
        // Perform your back navigation logic here
        navigate(ROUTES.PAYMENT_PAGE); // Change this to the appropriate route
      };

      window.addEventListener("popstate", handleLocationChange);

      return () => {
        window.removeEventListener("popstate", handleLocationChange);
      };
    }
  }, [navigate, isLoggedIn]);

  // useEffect(() => {
  //   if (typeof turnstile !== "undefined") {
  //     turnstile.ready(() => {
  //       if (!widgetId.current) {
  //         widgetId.current = turnstile.render("#cf-turnstile-otp", {
  //           sitekey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  //           theme: "light",
  //           callback: (tokenValue: string) => {
  //             setCloudFareToken(tokenValue)
  //             console.log("token", tokenValue)
  //           },
  //         });
  //       }
  //     });
  //   } else {
  //     console.error("Turnstile script not loaded.");
  //   }
  // }, []);

  const RegisterValidation = Yup.object().shape({
    code: Yup.string().required(
      "Please enter valid Unique Code"
    ),
    mobile: Yup.string()
      .required("*Please enter valid 10-digit number")
      .matches(
        /^[56789][0-9]{9}$/,

        "*Please enter valid 10-digit number"
      ),
    name: Yup.string()
      .required("Enter valid name")
      .matches(/^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/, "Enter valid name"),

    agree: Yup.boolean().oneOf(
      [true],
      " Please accept Terms and Conditions to proceed"
    ),
    smocker: Yup.boolean()
      .required("You are not eligible to participate")
      .oneOf([true], "You are not eligible to participate"),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
        "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // const navigate = useNavigate();

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };
  // useEffect(() => {
  //   // useEffect to set the referral code if available
  //   if (referralCodeFromUrl && !referralSet.current) {
  //     setFieldValue(referralCodeFromUrl);
  //     referralSet.current = true; // Mark as set to prevent multiple runs
  //   }
  // }, [referralCodeFromUrl, setFieldValue]);

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          code: "",
          mobile: "",
          name: "",
          smocker: false,
          agree: false,
          tokenValue
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          // setShowOtpForm(true)
          console.log(values);
          // setShowStateDropdown(false);
          // setShowOtpForm(true);
          // if (!tokenValue) {
          //   errors.setErrors({
          //     agree: "*Please complete captcha challenge",
          //   });
          //   return;
          // }
          if (values.agree) {
            console.log(values);
            startTimer();
            values.tokenValue = tokenValue
            API.register(values)
              .then(() => {
                gtagTrackEvent(GA_EVENTS.Registered);
                setShowOtpForm(true);
                startTimer();
              })
              .catch((err) => {
                const { messageId } = err;
                switch (messageId) {
                  case ERROR_IDS.invalidMobile:
                    errors.setErrors({
                      mobile: "Invalid Mobile Number",
                    });
                    break;
                    case ERROR_IDS.invalidUniqueCode:
                    errors.setErrors({
                      code: "Invalid Unique Code",
                    });
                    break;
                    case ERROR_IDS.codeAlreadyUsed:
                    errors.setErrors({
                      code: "This unique code is already used",
                    });
                    break;
                    case ERROR_IDS.dailyLimitReached:
                    errors.setErrors({
                      code: "Daily limit reached",
                    });
                    break;

                  default:
                    errors.setErrors({
                      mobile: err.message,
                    });
                    break;
                }
              });
          } else {
            errors.setErrors({
              agree: "*Please agree to the terms & conditions",
            });
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <img className="title-img" src={regtitle} alt="title" />
              <div className="register-form">
                {/* <p className="form-title ">REGISTRATION</p> */}

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    maxLength={35}
                    name="name"
                    onBlur={handleBlur}
                    placeholder="Name*"
                    pattern="[A-Za-z\s]*"
                  />
                </div>

                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.mobile}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder="Mobile Number*"
                  />
                </div>
                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.code}
                    name="code"
                    maxLength={12}
                    onBlur={handleBlur}
                    placeholder="Unique Code*"
                  />
                </div>
                <div className="input-group flex-center">
                  <label className="checkbox-container">
                    <span>
                      {" "}
                      I agree to the{" "}
                      <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                        <u className="terms-link">Terms & Conditions{""}</u>
                      </span>
                      <br />
                    </span>
                    <input
                      type="checkbox"
                      id="agree"
                      name="agree"
                      onChange={(e) => {
                        setFieldValue("agree", e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="input-group flex-center">
                  <span className="smoker-text">Are you above the legal drinking age?</span>
                </div>
                <label className="checkbox-container1">
                  <span> {"Yes"} </span>

                  <input
                    type="checkbox"
                    id="smocker"
                    name="smocker"
                    checked={values.smocker}
                    onChange={(e) => {
                      setFieldValue("smocker", e.target.checked);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="checkbox-container1">
                  <span> {"No"} </span>
                  <input
                    type="checkbox"
                    id="smocker"
                    name="smocker"
                    checked={!values.smocker}
                    onChange={(e) => {
                      setFieldValue("smocker", !e.target.checked);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <div className="input-group">
                  <input style={{ marginTop: "1rem" }}
                    className="grey-out"
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    // value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    disabled
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>

                {errors.name && touched.name && (
                  <p className="error">{errors.name}</p>
                )}
                {!errors.name && errors.mobile && touched.mobile && (
                  <p className="error">{errors.mobile}</p>
                )}

                {!errors.name &&
                  !errors.mobile &&
                  errors.code &&
                  touched.code && <p className="error">{errors.code}</p>}

                {/* <div style={{ margin: "1rem auto" }} id="cf-turnstile-otp"></div> */}
                {!errors.name &&
                  !errors.mobile &&
                  !errors.code &&
                  errors.agree &&
                  touched.agree && (
                    <p className="error">{errors.agree}</p>
                  )}

                {!errors.name &&
                  !errors.mobile &&
                  !errors.code &&
                  !errors.agree &&
                  errors.smocker &&
                  touched.smocker && (
                    <p className="error">{errors.smocker}</p>
                  )}
              </div>
              <button className="btn btn-primary" type="submit">
                GET OTP
              </button>

            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
          smocker: true,
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.WATCH_VIDEO);
          API.verifyOTP(values.otp)
            .then((response) => {
              console.log("inside verify",response);
              
              navigate(ROUTES.WATCH_VIDEO);
              // hideCaptchaBadge();
              // console.log("Access Token:", response.accessToken);
              store.dispatch(setAccessToken(response.token));
              store.dispatch(limit(response.limit));
              // store.dispatch(limit(response.amt));
              // localStorage.setItem("amt", response.amt.toString());
              store.dispatch(amt(response.amt))
              gtagTrackEvent(GA_EVENTS.Verify_OTP);
              const questionData = response.data;


              // if (response.limit) {
              //   navigate(ROUTES.PARTICPATED);
              // } else {
              //   navigate(ROUTES.WATCH_VIDEO);
              // }
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp: "Please enter a valid OTP",
                  });
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <img className="title-img" src={regtitle} alt="title" />
              <div className="otp-form">
                <div className="input-group">
                  <label className="input-label "></label>
                  <input
                    className="grey-out"
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    name="mobile"
                    maxLength={10}
                    onBlur={handleBlur}
                    placeholder="Mobile Number*"
                    disabled
                  />
                </div>
                <div className="input-group">
                  <label className="input-label"></label>
                  <input
                    className="grey-out"
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    name="code"
                    maxLength={12}
                    onBlur={handleBlur}
                    placeholder="Unique Code*"
                    disabled
                  />
                </div>
                <div className="input-group flex-center">
                  <label className="checkbox-container">
                    <span>
                      {" "}
                      I agree to the{" "}
                      <span onClick={() => showModal(MODAL_TYPES.TERMS)}>
                        <u className="terms-link">Terms & Conditions{""}</u>
                      </span>
                      <br />
                    </span>
                    <input
                      type="checkbox"
                      id="agree"
                      name="agree"
                      checked={values.smocker}

                      onChange={(e) => {
                        setFieldValue("agree",);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="input-group flex-center">
                  <span className="smoker-text">Are you above the legal drinking age?</span>
                </div>
                <label className="checkbox-container1">
                  <span> {"Yes"} </span>

                  <input
                    type="checkbox"
                    id="smocker"
                    name="smocker"
                    checked={values.smocker}
                    onChange={(e) => {
                      setFieldValue("smocker",);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="checkbox-container1">
                  <span> {"No"} </span>
                  <input
                    type="checkbox"
                    id="smocker"
                    name="smocker"
                    checked={!values.smocker}
                    onChange={(e) => {
                      setFieldValue("smocker",);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <div className="input-group">
                  <input style={{ marginTop: "1rem" }}
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
              </div>
              <button className="btn btn-primary" type="submit">
                VERIFY
              </button>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
