import "./PaymentPage.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
// import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import paymenttitle from "../../assets/images/paymenttitle.png"
import React, { useEffect, useState } from "react";
import API from "../../api";
import Confetti from "react-confetti";
import gif from "../../assets/images/congrats.gif";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";



interface PaymentPageProps {
  onSubmit: (values: any) => void;
}

const PaymentPage: React.FC<PaymentPageProps> = ({ onSubmit }) => {
  const MobileNumberValidation = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("*Please enter UPI linked mobile number")
      .matches(/^\d{10}$/, "*Please enter UPI linked mobile number"),
  });

  const navigate = useNavigate();

  const [cashbackAmount, setCashbackAmount] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [confettiStartY, setConfettiStartY] = useState(650); 

  useEffect(() => {
    API.getReferral()
  .then((response) => {
    if (response && response.winType) {
      let cashbackAmount;
      switch (response.winType) {
        case "cashback_10":
          cashbackAmount = "10";
          break;
        case "cashback_20":
          cashbackAmount = "20";
          break;
        case "cashback_50":
          cashbackAmount = "50";
          break;
        case "cashback_70":
          cashbackAmount = "70";
          break;
        case "cashback_100":
          cashbackAmount = "100";
          break;
        default:
          cashbackAmount = "0"; 
      }

      setCashbackAmount(cashbackAmount);
    }
  })
      .catch((error) => {
        console.error("Error fetching reward:", error);
      });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MainLayout className="register-page">
      <Confetti
        width={windowWidth}
        height={windowHeight}
        colors={["#C0C0C0", "#D3D3D3", "#A9A9A9"]} 
        numberOfPieces={200}
        recycle={false} 
        initialVelocityX={5}
        initialVelocityY={20}
        confettiSource={{ x: 0, y: confettiStartY, w: windowWidth, h: 10 }} 
      />
      <div className="congrats-form">
      <img
          src={gif}
          alt="Congratulation"
          className="congratulation-image"
        />
        <div className="congratulation-container">
          <p className="congratulation-title">Congratulations!</p>
          <p className="congratulation-text">
            You have won Cashback of
          </p>
          <p className="gradient-text cashback-amt">₹ {cashbackAmount}</p>
        </div>
      </div>
      <div className="redeem-payment-form">
        <div className="game-container">
          <Formik
            initialValues={{
              mobileNumber: "",
            }}
            validationSchema={MobileNumberValidation}
            onSubmit={async (values,{ setErrors }) => {
              // navigate(ROUTES.CONGRATULATION);
              try {
                await API.saveUPIDetails(values.mobileNumber);
                onSubmit(values);  // Execute additional actions if needed
                gtagTrackEvent(GA_EVENTS.UPI_click);
                navigate(ROUTES.CONGRATULATION);
              } catch (err: any) {
                console.error("Error submitting UPI ID", err);
                setErrors({ mobileNumber: err.message});
              }
            }}
          >
            {({ handleSubmit }) => (
              <Form className="mobile-number-form" onSubmit={handleSubmit}>
                {/* <img className="title-img"  src={paymenttitle} alt="title"/> */}

                <div className="input-group" style={{width:"95%"}}>
                  <label htmlFor="mobileNumber"></label>
                  <Field
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="Enter UPI linked Mobile Number*"
                    maxLength={10}
                  />
                {/* <p className="upi-text">*Please enter UPI linked mobile number.</p> */}

                  <ErrorMessage
                    name="mobileNumber"
                    component="p"
                    className="error-payment"
                  />
                </div>

                <div className="payment-btn-container">
                  <button className="btn btn-primary" type="submit" style={{position:"absolute", bottom:"auto"}}>
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default PaymentPage;
