import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup" title="Contact us">
      
      <p className="text3" style={{ textAlign: "center" }}>
        <a className="text3" target="_blank" href="tel:08069806393">
        <strong>Support number :</strong> 080-69806393
        </a>

      </p>
    </Popup>
  );
};

export default ContactUsPopup;
