import { Route, Routes, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
// import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import { useEffect, useState } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";

import Thankyou from "./pages/Design/Thankyou/Thankyou";
import { Participated } from "./pages/Participated/Participated";
import { useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Quiz } from "./pages/ouiz/Quiz";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import { Reward } from "./pages/Reward/Reward";
import { Congratulation } from "./pages/Congratulation/Congratulation";
import NotAllowed from "./pages/Notallowed/NotAllowed";
import WatchVideo from "./pages/WatchVideo.tsx/WatchVideo";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();
  const { search } = useLocation();
  const [isParam, setIsParasm] = useState<boolean>(false);
  const queryString: string = "qrcode";
  // const [selectedStyle, setSelectedStyle] = useState<string | null>(null);
  // const [selectedValue, setSelectedValue] = useState<string | null>(null);
  // const [selectedElement, setSelectedElement] = useState<string | null>(null);
  // const [selectedColor, setSelectedColor] = useState<string | null>("warm");
  // const [text, setText] = useState<string | null>("");
  const navigate = useNavigate();

  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    API.createUser().then((response: any) => {
      store.dispatch(setUserIdentification(response));
      if (!response.isLoggedIn && isLoggedIn) {
        logoutUser();
        toast.info("Your session has been expired");
      }
      // addCaptchaScript();
    });
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      logoutUser();
      navigate(ROUTES.REGISTER);
      toast.info("Your session has been expired");
    };
  }, []);

  return (
    <div className="App">
      {/* {isParam? ( */}
      <Routes>
        {/* <Route path={ROUTES.HOME} element={<Home />} /> */}
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route
          path={ROUTES.MCQ}
          element={
            <PrivateRoute>
              <Quiz />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.WATCH_VIDEO}
          element={
            <PrivateRoute>
              <WatchVideo />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.REWARD}
          element={
            <PrivateRoute>
              <Reward />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PAYMENT_PAGE}
          element={
            <PrivateRoute>
              <PaymentPage onSubmit={(values) => console.log(values)} />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.CONGRATULATION}
          element={
            <PrivateRoute>
              <Congratulation />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PARTICPATED}
          element={
            <PrivateRoute>
              <Participated />
            </PrivateRoute>
          }
        />


        {/*<Route path={ROUTES.COMPLETED} element={<Completed />} />*/}
      </Routes>
      {/* ):(<NotAllowed/>)} */}

      {/*<ToastContainer position="bottom-center" hideProgressBar={true} />*/}
    </div>
  );
}

export default App;
