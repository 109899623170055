import "./Congratulation.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
import { useDispatch, useSelector } from "react-redux";
// import {
//   isFirstGame,
//   setIndividualScore,
// } from "../../store/actions/authAction";
import { useAuthentication } from "../../hooks/useAuthentication";
import gif from "../../assets/images/congrats.gif";
import congrats from "../../assets/images/congrats-bg.png"
import Confetti from "react-confetti";
import InstagramIcon from "../../assets/images/instaicon.png"



import chocolate from "../../assets/images/malkist.png"


export const Congratulation = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [confettiStartY, setConfettiStartY] = useState(650);
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    {
      /*navigate(ROUTES.HOME);*/
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  })
  
  const handleShareClick = () => {
    // API.watchVideo();
   window.open("https://www.instagram.com/reel/C_0uaTWpMoI/?igsh=eXgwNHQ4a29rY2kw", "_blank");
  };

  return (
    <MainLayout className="register-page">
      {/* <img className="congrats-bg" src={congrats} alt="logo-top" />  */}
      {/* <Confetti
        width={windowWidth}
        height={windowHeight}
        colors={["#C0C0C0", "#D3D3D3", "#A9A9A9"]}
        numberOfPieces={200}
        recycle={false}
        initialVelocityX={5}
        initialVelocityY={20}
        confettiSource={{ x: 0, y: confettiStartY, w: windowWidth, h: 10 }}
      /> */}
      <div className="congrats-form">
          <img
          src={gif}
          alt="Congratulation"
          className="congratulation-image"
        />
        <div className="congratulation-container border">
          <p className="congratulation-title">Thank you!</p>
          <p className="congratulation-text">
            Your cashback will be credited to the selected payout made within 24-28 business hours.
          </p>
          <p className="congratulation-text">You are also eligible to win mega reward. If you are a winner, we will contact you.</p>
        </div>
        <img className="share-image" src={InstagramIcon} alt="title" onClick={handleShareClick} />

      </div>
      {/* <button style={{position:"relative"}} className="btn btn-primary" onClick={handleShareClick}>
        SHARE ON SOCIAL
      </button> */}

    </MainLayout>
  );
};
